import React, { useEffect, useState } from 'react'
import './team.css'
import {useSelector,useDispatch} from 'react-redux'
import {getAdmins,updateAdmins} from '../../features/auth/authSlice'
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../../features/upload/uploadSlice";
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';

const Team = () => {
  const user=JSON.parse(localStorage.getItem("user"))
    const dispatch=useDispatch()
    const teamState=useSelector((state)=>state?.auth?.team)
  const imgState = useSelector((state) => state.upload.images);
const [firstname,setFirstname]=useState("")
const [lastname,setLastname]=useState("")
const [email,setEmail]=useState("")
const [mobile,setMobile]=useState(null)
const [image,setImage]=useState(null)
const [userId,setUserId]=useState("")
useEffect(()=>{
    setImage(imgState[0])
},[imgState])

    useEffect(()=>{
        dispatch(getAdmins())
    },[dispatch])
    const deleteImg=(id)=>{
        dispatch(delImg(id))
        setImage(null)
      }
      const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
const [open,setOpen]=useState(false)

      const setInfo=(item)=>{
        if(user?.email===item?.email || user?.email==="keshvendra638701@gmail.com" || user?.email==="ujjawalsingh@voguemine.com" || user?.email==="nimishmehra@gmail.com"){
          setOpen(true)
          setFirstname(item?.firstname)
          setLastname(item?.lastname)
          setEmail(item?.email)
          setMobile(item?.mobile)
          setImage(item?.image)
          setUserId(item?._id)
        }
          
      }

      const updateProfile=()=>{
if(firstname==="" || lastname==="" || email==="" || mobile===""){
    toast.error("Fill Info")
}
else{
    dispatch(updateAdmins({
        userId:userId,updates:{
            firstname:firstname,
            lastname:lastname,
            email:email,
            mobile:mobile,
            image:image
        }
    }))
}
      }



  return (
    <div className='my-team'>
        <div className="head">
            <p>OUR TEAM</p>
        </div>
        <div className="team">
            {
                teamState?.map((item,index)=>{
                    return <div className="team-card" onClick={(e)=>setInfo(item)} key={index}>
                        <img src={item?.image?.url} alt="" />
                        <p className="name">{item?.firstname}</p>
                        <p className="phone">{item?.mobile}</p>
                    </div>
                })
            }
        </div>
        <div className="infos" style={{display:open===true?"flex":"none"}}>
<p className="delete" onClick={(e)=>setOpen(false)}><CloseIcon/></p>

            <div className="information">
            <div className="left">
            <div className="image">
              <p>Image</p>
              {
                !image?
                <Dropzone
                onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
                style={{width:'100px',height:'100x',border:'1px solid grey',borderRadius:'5px'}}
               >
                {({ getRootProps, getInputProps }) => (
                  <section >
                    <div {...getRootProps()} className="img-box-in">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              :
              <div>
                <img src={modifyCloudinaryUrl(image?.url)} alt="profile" />
<p className="delete" onClick={(e)=>deleteImg(image?.public_id)}><CloseIcon/></p>
              </div>

              }
              
           
            </div>

            </div>
<div className="right">
<input type="text" placeholder='Firstname' value={firstname} onChange={(e)=>setFirstname(e.target.value)}/>
<input type="text" placeholder='Lastname' value={lastname} onChange={(e)=>setLastname(e.target.value)}/>
<input type="email" placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
<input type="number" placeholder='Mobile' value={mobile} onChange={(e)=>setMobile(e.target.value)}/>
<button onClick={updateProfile}>UPDATE</button>

</div>
            </div>
        </div>
      
    </div>
  )
}

export default Team
