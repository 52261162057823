import React, { useState,useEffect,useRef } from 'react'
import './header.css'
import { useDispatch, useSelector } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AppsIcon from '@mui/icons-material/Apps';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BookIcon from '@mui/icons-material/Book';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import {useNavigate,Link} from 'react-router-dom'
import {getsearchProducts} from '../../features/product/productSlice'
import logo from './logo.png'
import vogue from './vogue.jpg'
import {getTodayData} from '../../features/auth/authSlice'

function ListItem({ text, onClick, isSelected ,icon,url}) {
  return (
    <li
      onClick={onClick}
      style={{ backgroundColor: isSelected ? 'white' : 'transparent' }}
    >
      {icon} <Link to={url}>{text}</Link>
    </li>
  );
}
const Header = () => {

const [headClose,setHeadClose]=useState("-100%")
const [selectedItem, setSelectedItem] = useState(null);
const [logOpen,setLogOpen]=useState("none")
const [isHead2Open, setIsHead2Open] = useState(false); // Define isHead2Open state variable
const dispatch=useDispatch()
const [svalue,setSvalue]=useState("") // Ref for the audio element

const navigate = useNavigate();
const productState2=useSelector((state)=>state?.product)
const todayDataState=useSelector(state=>state?.auth?.todayData)
const productState1 = useSelector((state) => state?.product?.searchProduct);
const [sProducts,setSproducts]=useState([])
const [none,setNone]=useState("none")
const [loading,setLoading]=useState(true)
const {isLoading,isSuccess}=productState2
useEffect(()=>{
if(isLoading===true && productState1){
  setLoading(true)
}
else if(isSuccess===true && productState1){
  setLoading(false)
}
},[productState1])
useEffect(()=>{
  dispatch(getTodayData())
},[dispatch])


const handleItemClick = (index) => {
  setSelectedItem(index);
  setHeadClose("-100%")
  setIsHead2Open(false)
};
const profileClick=()=>{
  if(logOpen==="none"){
    setLogOpen("block")
  }else{
    setLogOpen("none")
  }
}

  const hamClick=()=>{
    if(headClose==="-100%"){
      setHeadClose("0")
      setIsHead2Open(true)
    }
    else{
      setHeadClose("-100%")
      setIsHead2Open(false)
    }
  }

  const setHeadClose1=()=>{
    setHeadClose("-100%")
      setIsHead2Open(false)
  }

  const headerRef = useRef(null);
const admin=JSON.parse(localStorage.getItem("user"))
const menuList=[
  {
    name:"Dashboard",
    icon:<DashboardIcon className='liIcon'/>,
    link:"/"
  },
  {
    name:"Products",
    icon:<Inventory2Icon className='liIcon'/>,
    link:"/products"
  },
  {
    name:"Collections",
    icon:<AppsIcon className='liIcon'/>,
    link:"/collection"
  },
  {
    name:"Orders",
    icon:<CardGiftcardIcon className='liIcon'/>,
    link:"/orders"
  },
  {
    name:"Abandoneds",
    icon:<CardGiftcardIcon className='liIcon'/>,
    link:"/abandoneds"
  },
  {
    name:"Coupons",
    icon:<LocalOfferIcon className='liIcon'/>,
    link:"/coupon"
  },
  {
    name:"Blogs",
    icon:<BookIcon className='liIcon'/>,
    link:"/blogs"
  },
  {
    name:"Banners",
    icon:<ViewCarouselIcon className='liIcon'/>,
    link:"/banner"
  },{
    name:"Customer",
    icon:<GroupsIcon className='liIcon'/>,
    link:"/customer"
  },
  {
    name:"Old Orders",
    icon:<CardGiftcardIcon className='liIcon'/>,
    link:"/oldorders"
  },
  {
    name:"History",
    icon:<SettingsIcon className='liIcon'/>,
    link:"/history"
  },
  {
    name:"Team",
    icon:<SettingsIcon className='liIcon'/>,
    link:"/team"
  }

]




useEffect(() => {
  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setHeadClose("-100%");
      setIsHead2Open(false)
      setNone("none")

    }
  };

  document?.addEventListener("mousedown", handleClickOutside);

  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);
const handleKeyDown = (event) => {
  if (event.key === 'Enter' && svalue!=="") {
    dispatch(getsearchProducts({search:svalue}))
    setLoading(true)
    setNone("block")

  }
};

useEffect(()=>{
setSproducts(productState1?.products)
},[productState1])

const productRedirect=(id)=>{
  setNone("none")
  navigate(`/addProduct/${id}`)
}
const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};
  return (
    <div className='header' ref={headerRef}>
      {isHead2Open && <div className="overlay" onClick={setHeadClose1}></div>}
      <div className="head1">
        <div className="left">
          <button>
            <MenuIcon onClick={hamClick} style={{cursor:'pointer'}}/>
          </button>
        
          <img src={logo} alt="" className='logo logo1' onClick={()=>navigate("/")} style={{cursor:'pointer'}}/>


        </div>
        <div className="center">
          <div className="search">
<button><SearchIcon/></button>
<input type="search" placeholder="Search for Products..." value={svalue} onChange={(e)=>setSvalue(e.target.value)} onKeyDown={handleKeyDown}/>
  
<div className="s-result" style={{display:none}}>
  {
    loading===true?
    <p>Loading...</p>
    :
    sProducts?.length>0?
    sProducts?.map((item,index)=>{
      return <div className="sval" key={index} onClick={()=>productRedirect(item?._id)}>
      <img src={modifyCloudinaryUrl(item?.images[0]?.url)} alt="" />
      <p className="sku">{item?.sku}</p>
      <p className="title">{item?.title}</p>
    </div>
    })
    :
    <p>No Item</p>

  }

  
</div>
          </div>
        </div>
        <div className="right">
<button>
<Badge badgeContent={todayDataState && todayDataState[0]?.totalCount} color="primary">
      <NotificationsActiveIcon color="action" />
    </Badge></button>
    {/* <p onClick={profileClick} className='admin'><span>Hi,</span><span>{admin && admin?.firstname}</span></p> */}
<img src={admin?.image?.url || vogue} alt="" onClick={profileClick} />
<div className="logout" style={{display:logOpen}}>
  <ul>
    <li><AccountCircleIcon className='log-icon'/>Profile</li>
    <li onClick={()=>(localStorage.clear(),navigate("/login"),setLogOpen("none"))}><LogoutIcon className='log-icon'/>Logout</li>
  </ul>
</div>
        </div>
      </div>
      <div className="head2" style={{left:headClose}} >
      <div>
        <ul>
          {
            menuList.map((item,index)=>{
              return <ListItem
              url={item.link}
              icon={item.icon}
              key={index}
              text={item.name}
              
              onClick={() =>( handleItemClick(index),navigate(item.link))}
              isSelected={selectedItem === index}
            >
            </ListItem>
            })
          }



        </ul>
      </div>
      
      </div>
    </div>
  )
}

export default Header
