import React, { useState, useEffect } from 'react';
import {useFormik} from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import './login.css'
import {login} from '../../features/auth/authSlice';
import {useNavigate} from 'react-router-dom'

  const loginSchema=yup.object({
    email:yup.string().email("Email should be valid").required("Email is Required"),
    password:yup.string().required("Password is required")
  })
const Login = () => {

    const authState=useSelector(state=>state?.auth)
    const { user, isError, isSuccess, isLoading, message } = authState;

const navigate=useNavigate()
const dispatch=useDispatch()
      const formik1=useFormik({
        initialValues:{
          email:"",
          password:"",
        },
        validationSchema:loginSchema,
        onSubmit:(values)=>{
          dispatch(login(values))
        }
      })

      useEffect(() => {
        if (isSuccess) {
          navigate("/");
        } else {
          navigate("/login");
        }
      }, [user, isError, isSuccess, isLoading]);
      
      
  return (
    <div className='logins'>
      <div className="login-box">

<div className="login-details">
            <p>Login</p>
            <form action="" onSubmit={formik1.handleSubmit}>
            <div className="email">
                <input type="email" name="email" id="email" placeholder='Email' value={formik1.values.email} onChange={formik1.handleChange("email")} onBlur={formik1.handleBlur("email")}/>
                <div className="error">
                    {formik1.touched.email && formik1.errors.email}
                  </div>
            </div>
            <div className="password">
                <input type="password" name="password" id="password" placeholder='Password' value={formik1.values.password} onChange={formik1.handleChange("password")} onBlur={formik1.handleBlur("password")}/>
                <div className="error">
                    {formik1.touched.password && formik1.errors.password}
                  </div>
               
            </div>
            <input type="submit" value="Login" className='submit'/>
            </form>
        </div>
        
        
      </div>
    </div>
  )
}

export default Login
