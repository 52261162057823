import React, { useState,useEffect } from 'react'
import ReactQuill from "react-quill";
import './banner.css'
import {createhistory} from '../../features/auth/authSlice'
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../../features/upload/uploadSlice";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom'
import { createBanner ,getABanner,resetState, updateABanner,deleteABanner} from '../../features/banner/bannerSlice';
import toast from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';

const Banner = () => {
  const user=JSON.parse(localStorage.getItem("user"))

  const [title,setTitle]=useState("")
  const [alt,setAlt]=useState("")

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const location = useLocation()
  const getBannerId = location.pathname.split("/")[2];
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const imgState = useSelector((state) => state.upload.images);
  const bannerState=useSelector((state)=>state?.banner?.singleBanner)
  console.log(bannerState)
  useEffect(()=>{
    setTitle(bannerState?.title)     
    setImage1(bannerState?.images?.[0] || null);
    setImage2(bannerState?.images?.[1] || null);
      setAlt(bannerState?.alt)


  },[bannerState])
  useEffect(() => {

    if (getBannerId !== undefined) {
      dispatch(getABanner(getBannerId));
    } else {
      dispatch(resetState());
    }
  }, [getBannerId]);

  const handleImageUpload = (acceptedFiles, setImage) => {
    dispatch(uploadImg(acceptedFiles)).then((result) => {
      const uploadedImage = result?.payload?.[0];
      if (uploadedImage) {
        setImage(uploadedImage);
      }
    });
  };


  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });


const createABanner=()=>{
  if(title===""){
    toast.error("Please Fill All The Required Fields")
  }
  
   else {
    const images = [image1, image2];
    if (getBannerId !== undefined) {
      dispatch(updateABanner({id:getBannerId, data:{
        title:title,
        images,
        alt:alt,
        createdAt:bannerState?.createdAt,
      }
      }))
      dispatch(createhistory({name:user?.firstname,title:title,sku:title,productchange:`Updated a Banner`,time:new Date()}))

    }

   else{
    dispatch(createBanner({title:title,
      alt:alt,
      images:images,
    }))
    dispatch(createhistory({name:user?.firstname,title:title,sku:title,productchange:`Created a Banner`,time:new Date()}))

   }
  }
}
const deleteImg = (image, setImage) => {
  dispatch(delImg(image.public_id));
  setImage(null);
};
const deletebanner=()=>{
  dispatch(deleteABanner(getBannerId))
  dispatch(createhistory({name:user?.firstname,title:title,sku:title,productchange:`Deleted a Banner`,time:new Date()}))
  setTimeout(()=>{
navigate("/banner")
  },1000)
}
const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};
  return (
    <div className='banner'>
        <div className="banner-head">
            <p>Create banner</p>
            <div className="right">
            <button onClick={deletebanner}>Delete</button>
            <button onClick={createABanner}>Save</button>
        </div>
        </div>
      <div className="banner-page">
      <div className="category">
        <p>Name</p>
        <input type="text" placeholder='Enter Name' onChange={(e)=>setTitle(e.target.value)} value={title}/>

        </div>
        
        <div className="image">
          <p>Image 1</p>
          {
            image1 === null ?
              <Dropzone onDrop={(acceptedFiles) => handleImageUpload(acceptedFiles, setImage1)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="img-box-in">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop an image here, or click to select an image</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              :
              <div className='second-div'>
                <img src={modifyCloudinaryUrl(image1?.url)} alt={title} />
                <p className="delete" onClick={() => deleteImg(image1, setImage1)}><CloseIcon /></p>
              </div>
          }
        </div>

        <div className="image">
          <p>Image 2</p>
          {
            image2 === null ?
              <Dropzone onDrop={(acceptedFiles) => handleImageUpload(acceptedFiles, setImage2)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="img-box-in">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop an image here, or click to select an image</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              :
              <div className='second-div'>
                <img src={modifyCloudinaryUrl(image2?.url)} alt={title} />
                <p className="delete" onClick={() => deleteImg(image2, setImage2)}><CloseIcon /></p>
              </div>
          }
        </div>
        <div className="category">
        <p>alt</p>
        <input type="text" placeholder='Enter alt' onChange={(e)=>setAlt(e.target.value)} value={alt}/>

        </div>
       
        
      </div>
    </div>
  )
}

export default Banner
